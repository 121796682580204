// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$travel-planner-ui-primary: mat.define-palette(mat.$indigo-palette);
$travel-planner-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$travel-planner-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$travel-planner-ui-theme: mat.define-light-theme((
  color: (
    primary: $travel-planner-ui-primary,
    accent: $travel-planner-ui-accent,
    warn: $travel-planner-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($travel-planner-ui-theme);

$dark-text-primary: #282828;
$light-text-primary: white;

$tanzania-travelers-palette: (
  50: #f7e8d7,
  100: #ddc7b3,
  200: #bea38d,
  300: #9e8065,
  400: #9e8065,
  //400: #876547,
  500: #9e8065,
  //500: #6f4c2b,
  600: #9e8065,
  //600: #654325,
  700: #9e8065,
  //700: #56371d,
  800: #9e8065,
  //800: #492b15,
  900: #9e8065,
  //900: #3a1d0a,
  //A100: #8c9eff,
  //A200: #536dfe,
  //A400: #3d5afe,
  //A700: #304ffe,
  contrast: (
    50: $dark-text-primary,
    100: $dark-text-primary,
    200: $dark-text-primary,
    300: $light-text-primary,
    400: $light-text-primary,
    500: $light-text-primary,
    600: $light-text-primary,
    700: $light-text-primary,
    800: $light-text-primary,
    900: $light-text-primary,
    //A100: $dark-text-primary,
    //A200: $light-text-primary,
    //A400: $light-text-primary,
    //A700: $light-text-primary,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tanzania-travelers-primary: mat.define-palette($tanzania-travelers-palette, 300);
$tanzania-travelers-accent: mat.define-palette($tanzania-travelers-palette, 300);

// The warn palette is optional (defaults to red).
//$tanzania-travelers-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tanzania-travelers-theme: mat.define-light-theme((
  color: (
    primary: $tanzania-travelers-primary,
    accent: $tanzania-travelers-accent,
    //warn: $tanzania-travelers-warn,
  )
));

@include mat.all-component-themes($tanzania-travelers-theme);


//noinspection CssUnknownTarget
@import 'overlayscrollbars/overlayscrollbars.css';

// =================================================================
//                         GLOBAL STYLES
// =================================================================
@import "variables";

html, body {
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fbfbfb;
}

* {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

// =========================
//         UTILITY
// =========================
.shadow {
  box-shadow: 0 2px 12px 0 rgb(221 221 221 / 78%);
}

.resizable-container-drag-left::after {
  content: '';
  position: absolute;
  left: 0;
  transform: translateX(-5px);
  width: 10px;
  top: 0;
  bottom: 0;
  cursor: col-resize;
}

.resizable-container-drag-right::after {
  content: '';
  position: absolute;
  right: 0;
  transform: translateX(5px);
  width: 10px;
  top: 0;
  bottom: 0;
  cursor: col-resize;
}

.display-none {
  display: none;
}

// =========================
//     Material Buttons
// =========================

button.mdc-button.square-icon {
  width: 32px;
  max-width: 32px;
  min-width: 32px;
  height: 32px;
  max-height: 32px;
  min-height: 32px;
  padding: 0;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  mat-icon {
    margin-right: 0;
    margin-left: 0;
    font-size: 1.25rem;
    height: 1.25rem;
    width: 1.25rem;
    margin-top: 2px;
  }
}


// =========================
//     Material Icons
// =========================

button.icon-button.icon-18 {
  width: 26px;
  height: 26px;
  padding: 0;

  mat-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
  }

  .mat-mdc-button-touch-target {
    width: 26px;
    height: 26px;
  }
}

// =========================
//     Material Forms
// =========================
mat-form-field {
  width: 100%;
}

.mat-mdc-autocomplete-panel {
  min-width: 128px;
}

// =========================
//     Material Dialog
// =========================
.dialog-fullscreen {
  .mat-mdc-dialog-container {
    border-radius: unset;
    padding-bottom: 60vh;
  }
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

// =========================
//       MAT TAB NAV
// =========================

app-tab-nav {
  background-color: white;
}

.nav-link-small .mat-mdc-tab-link {
  height: 32px;
  min-width: 96px;
}

.nav-without-border-bottom {
  .mat-mdc-tab-nav-bar, .mat-mdc-tab-header {
    border-bottom: none;
  }
}

mat-tab-group .mat-mdc-tab-label {
  min-width: 90px;
  width: 100%;
}

// =========================
//       TYPOGRAPHY
// =========================
h1 {
  font-weight: 500;
}

h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

a {
  color: $text-normal;
  text-decoration: none;
  display: block;

  &.highlight-background:hover {
    background-color: $light-gray-highlight;
  }
}

.warn {
  color: $error-color;
}

// =========================
//      Custom Table
// =========================
app-table {
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    background-color: white;
  }

  .row-container {
    display: block;
    cursor: pointer;
    color: $text-normal;
    text-decoration: none;
    border-bottom: $gray-border 1px solid;

    &:hover {
      background-color: $light-gray-highlight;
    }
  }
}

.table-rounded {
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    min-width: unset;
    border: $gray-border 1px solid;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 2em;
    width: calc(100% - 4em);
  }

  .table-container {
    .row-container:last-child {
      border-bottom: none;
    }

    .spacer {
      display: block;
      height: 2em;
      width: 100%;

      &.spacer-big {
        height: 6em;
      }
    }
  }
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}
