
$primary-color: #9e8065;
$warn-color: #d98200;
$error-color: #d90000;
$red-color: #d90000;
$success-color: #0ca50c;

$text-normal: rgba(0, 0, 0, 0.87);
$text-grey: rgb(122, 122, 122);
$text-grey-light: rgb(176, 176, 176);

$light-gray-highlight: rgba(224, 224, 224, 0.4);
$gray-scrollbar: rgba(145, 145, 145, 0.4);

$gray-border: rgb(224, 224, 224);
